export const filterMixin = {
    methods: {
        countByPeriod(data) {
            const now = new Date();
            const currentMonth = now.getMonth() + 1;
            const currentYear = now.getFullYear();
            const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
            const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;

            let currentMonthCount = 0;
            let previousMonthCount = 0;
            let currentYearCount = 0;

            data.forEach(item => {
                const birthDate = new Date(item.created_at);
                const birthMonth = birthDate.getMonth() + 1;
                const birthYear = birthDate.getFullYear();

                if (birthYear === currentYear) {
                    currentYearCount++;
                    if (birthMonth === currentMonth) {
                        currentMonthCount++;
                    } else if (birthMonth === previousMonth && birthYear === previousYear) {
                        previousMonthCount++;
                    }
                }
            });

            return {
                currentMonth: currentMonthCount,
                previousMonth: previousMonthCount,
                currentYear: currentYearCount
            };
        },
        filterByType(data, filterType) {
            const now = new Date();
            const currentMonth = now.getMonth() + 1;
            const currentYear = now.getFullYear();
            const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
            const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear - 1;
        
            return data.filter(item => {
                const birthDate = new Date(item.birthdate);
                const birthMonth = birthDate.getMonth() + 1;
                const birthYear = birthDate.getFullYear();
        
                switch (filterType) {
                    case "currentMonth":
                        return birthYear === currentYear && birthMonth === currentMonth;
                    case "lastMonth":
                        return birthYear === (currentMonth === 1 ? previousYear : currentYear) && birthMonth === previousMonth;
                    case "currentYear":
                        return birthYear === currentYear;
                    case "previousYear":
                        return birthYear === previousYear;
                    default:
                        return false;
                }
            });
        }
    }
};